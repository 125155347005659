<template>
	<BlockEcommerceProductList
		v-bind="{
			blockId,
			blockStyle,
			textColorVars,
			isProductListShown,
			productsPerPage,
			columnCount,
			productPages,
			productIds,
			productCategoryId,
			isButtonEnabled,
			buttonDisplay,
			buttonText,
			buttonStyle,
			buttonType,
			buttonBorderWidth,
			isLoading,
			ribbonStyle
		}"
		:products="productList"
		:translations="ecommerceTranslations"
		:is-eager="lcp.type === 'block-ecommerce-product-list' && lcp.id === blockId"
		:site-id="siteId"
		:variants-quantity="variantsQuantity"
		@page-changed="handlePageChange"
		@button-click="handleButtonClick"
	/>
</template>

<script>
import {
	useStore,
	mapActions,
	mapGetters,
	mapState,
} from 'vuex';
import {
	defineComponent,
	computed,
} from 'vue';
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';

import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import { useBlockEcommerceProductList } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProductList';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { useEcommerceModal } from '@/components/ecommerce/modals/useEcommerceModal';

export default defineComponent({
	components: {
		BlockEcommerceProductList,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
		lcp: {
			type: Object,
			default: () => ({}),
		},
		ecommerceTranslations: {
			type: Object,
			default: () => ({}),
		},
		currentLocale: {
			type: String,
			default: SYSTEM_LOCALE,
		},
		blocks: {
			type: Object,
			default: () => ({}),
		},
	},

	setup(props) {
		const {
			state,
			getters,
		} = useStore();
		const isLoading = computed(() => isAppPrerendering || state.ecommerce?.isLoading);
		const {
			openEcommerceModal,
			setProductPreviewData,
		} = useEcommerceModal(props);
		const stateProductPages = computed(() => getters['ecommerce/productPages']);
		const isStoreTypeZyro = computed(() => getters['ecommerce/isStoreTypeZyro']);
		const products = computed(() => state.ecommerce?.products);

		const {
			productList,
			productPages,
			blockStyle,
			textColorVars,
			columnCount,
			productsPerPage,
			productIds,
			productCategoryId,
			isButtonEnabled,
			buttonDisplay,
			buttonText,
			buttonStyle,
			buttonType,
			buttonBorderWidth,
			ribbonStyle,
		} = useBlockEcommerceProductList(props, {
			productPages: stateProductPages,
			isStoreTypeZyro,
			products,
		});

		return {
			productList,
			productPages,
			blockStyle,
			textColorVars,
			isLoading,
			columnCount,
			productsPerPage,
			productIds,
			productCategoryId,
			openEcommerceModal,
			setProductPreviewData,
			isButtonEnabled,
			buttonDisplay,
			buttonText,
			buttonStyle,
			buttonType,
			buttonBorderWidth,
			ribbonStyle,
		};
	},

	computed: {
		...mapGetters([
			'siteId',
			'meta',
		]),
		...mapState('ecommerce', ['variantsQuantity']),
		isProductListShown() {
			if (this.meta.defaultLocale && this.currentLocale !== this.meta.defaultLocale) {
				return false;
			}

			return !!this.productList?.length;
		},
	},

	methods: {
		...mapActions('ecommerce', ['getProducts']),
		handlePageChange(idsToFetch) {
			this.getProducts(idsToFetch);
		},
		handleButtonClick(productPageData) {
			const ecommerceBlocks = Object.keys(this.blocks).filter((key) => this.blocks[key].type === 'BlockEcommerceProduct');
			const pageProductBlockId = ecommerceBlocks.find((block) => productPageData.blocks.includes(block));

			this.setProductPreviewData(this.blocks[pageProductBlockId]);
			this.openEcommerceModal('EcommerceProductPreview');
		},
	},

});
</script>
